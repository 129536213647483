<template>
  <div class="layout-topbar">
    <button class="p-link layout-menu-button" @click="onMenuToggle">
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <!--<button
        type="button"
        class="p-link"
        label="Toggle"
        @click="onSettingToggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      >
        <span class="layout-topbar-item-text">Settings</span>
        <span class="layout-topbar-icon pi pi-cog"></span>
      </button>-->
      <button
        type="button"
        class="p-link"
        label="Toggle"
        @click="onSettingToggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
      >
        <span>{{ userName }}</span
        >&nbsp;
        <span class="layout-topbar-item-text">User</span>
        <span class="layout-topbar-icon pi pi-user"></span>
      </button>
      <!--<button class="p-link">
        <span class="layout-topbar-item-text">User</span>
        <span class="layout-topbar-icon pi pi-user" v-tooltip="userName"></span>
      </button>-->
    </div>
  </div>
  <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          label: 'ตั้งค่าเกี่ยวกับคุณ',
          items: [
            {
              label: 'แก้ไขข้อมูลส่วนตัว',
              icon: 'pi pi-user-edit',
              command: () => this.$router.push({ path: '/profile/info' })
            },
            {
              label: 'เปลี่ยนข้อมูลรหัสผ่าน',
              icon: 'pi pi-key',
              command: () => this.$router.push({ path: '/profile/password' })
            },
            {
              label: 'ถอนสิทธิ์การใช้งาน',
              icon: 'pi pi-user-minus',
              command: () => this.$router.push({ path: '/profile/revoke' })
            },
            {
              label: 'ประวัติการเข้าใช้งาน',
              icon: 'pi pi-calendar',
              command: () =>
                this.$router.push({ path: '/profile/activity/history' })
            },
            {
              label: 'ออกจากระบบ',
              icon: 'pi pi-arrow-circle-right',
              command: () => this.$router.push({ path: '/logout' })
            }
          ]
        }
      ]
    }
  },
  computed: {
    userName() {
      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea,
        userName,
        area
      } = this.$store.getters['oauth/session']
      const areaName = area ? ` (${area?.areaName})` : ''
      const { levels_ } = this.$store.state.constance
      return ` ${userName}  (  ${levels_[userLevel]} )${areaName}`
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onSettingToggle(event) {
      this.$refs.menu.toggle(event)
    }
  }
}
</script>
