<template>
  <div class="box" id="div-aging-banner"></div>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />

    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <!--<div class="layout-logo">
          <router-link to="/">
            <img alt="Logo" :src="logo" />
          </router-link>
        </div>-->

        <!-- <AppProfile />-->
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>

    <div class="layout-main">
      <router-view />
    </div>

    <!--<AppConfig
      :layoutMode="layoutMode"
      :layoutColorMode="layoutColorMode"
      @layout-change="onLayoutChange"
      @layout-color-change="onLayoutColorChange"
    />-->

    <!--<AppFooter />-->
  </div>
  <Toast position="top-center" />
  <ConfirmDialog :style="{ width: '400px' }" />
</template>
<script>
import AppTopBar from '@/layouts/AppTopBar'
//import AppProfile from "@/layouts/AppProfile";
import AppMenu from '@/layouts/AppMenu'
//import AppConfig from "@/layouts/AppConfig";
//import AppFooter from "@/layouts/AppFooter";

export default {
  name: 'Private',
  data() {
    return {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false
    }
  },
  watch: {
    $route() {
      this.menuActive = false
      this.$toast.removeAllGroups()
    }
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
      this.menuClick = false
    },
    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true
          }
          this.overlayMenuActive = !this.overlayMenuActive
          this.mobileMenuActive = false
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },
    onSidebarClick() {
      this.menuClick = true
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += ' ' + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else
        element.className = element.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        )
    },
    isDesktop() {
      return window.innerWidth > 1024
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive
        else return true
      } else {
        return true
      }
    }
  },
  computed: {
    menu() {
      const menus = {
        dashboard: {
          label: 'Dashboard',
          icon: 'pi pi-fw pi-home',
          to: '/dashboard'
        },
        elder: {
          label: 'จัดการข้อมูลผู้สูงอายุ',
          icon: 'pi pi-fw pi-list',
          to: '/elder/list'
        },
        analytic: {
          label: 'สถานะการสำรวจข้อมูลผู้สูงอายุ',
          icon: 'pi pi-chart-bar',
          to: '/analytic/index'
        },
        importHealth: {
          label: 'นำเข้าข้อมูลสุขภาพ',
          icon: 'pi pi-fw pi-list',
          to: '/elder/import/health'
        },
        area: {
          label: 'จัดการข้อมูลพื้นที่',
          icon: 'pi pi-fw pi-list',
          to: '/area/list'
        },
        user: {
          label: 'จัดการข้อมูลผู้ใช้งาน',
          icon: 'pi pi-fw pi-list',
          to: '/user/list'
        },
        /*boxplot: {
          label: 'วิเคราะห์ข้อมูลแบบ',
          icon: 'pi pi-chart-bar',
          to: '/plot/boxplot'
        },*/
        mltrain: {
          label: 'เฟส 1 - แบบจำลองการประเมินความสามารถในการทำงาน',
          icon: 'pi pi-discord',
          to: '/prediction/mltrain'
        },
        careertrain: {
          label: 'เฟส 1 - แบบจำลองการประเมินความสามารถในการประกอบอาชีพ',
          icon: 'pi pi-discord',
          to: '/prediction/career'
        },
        mltrainHealth: {
          label: 'เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรสุขภาพ)',
          icon: 'pi pi-discord',
          to: '/prediction/mltrainHealth'
        },
        mltrainTotal: {
          label: 'เฟส 2 - แบบจำลองการประเมินความสามารถในการทำงาน (ตัวแปรรวม)',
          icon: 'pi pi-discord',
          to: '/prediction/mltrainTotal'
        },
        careerByCareerTrain: {
          label: 'เฟส 2 – แบบจำลองประเมินความสามารถในการประกอบอาชีพของแต่ละอาชีพ​',
          icon: 'pi pi-discord',
          to: '/prediction/careerByCareerTrain'
        }
        /* barplot: {
          label: 'กราฟวิเคราะห์ข้อมูล',
          icon: 'pi pi-chart-bar',
          to: '/plot/barplot'
        },*/
      }

      // handle menus privilege
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      if (codeId) {
        /*if (!['OL', 'AL'].includes(userLevel)) {
          delete menus['elder']
        }*/
        if (!['AC'].includes(userLevel)) {
          delete menus['area']
          delete menus['importHealth']
        }
        if (!['AC', 'AL'].includes(userLevel)) {
          delete menus['user']
        }
        if (!['AL'].includes(userLevel)) {
          delete menus['import']
        }
        if (['OL'].includes(userLevel)) {
          delete menus['elder']
        }
      }
      return Object.keys(menus).map((k, i) => menus[k])
    },
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active':
            this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$appState.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.ripple === false
        }
      ]
    },
    sidebarClass() {
      return [
        'layout-sidebar'
        /*{
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },*/
      ]
    },
    logo() {
      return this.layoutColorMode === 'dark'
        ? require('@/assets/layout/images/logo-white.svg')
        : require('@/assets/logo.png')
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden')
    else this.removeClass(document.body, 'body-overflow-hidden')
  },
  components: {
    AppTopBar: AppTopBar,
    //AppProfile: AppProfile,
    AppMenu: AppMenu
    //AppConfig: AppConfig,
    //AppFooter: AppFooter,
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/style.scss';

.layout-wrapper.layout-static {
  :deep(.layout-topbar) {
    background: #002d66;
    background: linear-gradient(to right, #002d66 0%, #0388e5 100%);
    position: absolute;
  }
  :deep(.layout-sidebar) {
    background: #002d66;
    position: absolute;
    /*background: linear-gradient(to bottom, #0388e5 50%, #002d66 100%);*/
    .layout-profile li button,
    li a,
    .layout-profile-link {
      color: white;
    }
  }
  :deep(.p-fieldset-legend) {
    background: linear-gradient(to right, #002d66 0%, #0388e5 100%);
    color: white;
  }
  :deep(.layout-main) {
    background-image: url('../assets/images/aging-homescreen-layout.jpg');

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media print {
    :deep(.print-hidden) {
      display: none;
    }
    :deep(fieldset) {
      page-break-before: always;
    }
    :deep(.p-sm-6) {
      page-break-before: always;
    }
    :deep(.p-fieldset-legend) {
      color: black;
      font-size: 16px;
    }
  }
}

#div-aging-banner {
  background-image: url('../assets/images/aging-banner-small.png');
  height: 85px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 360px) {
  :global(#div-aging-banner) {
    height: 30px !important;
    background-size: contain !important;
  }
}
</style>
